import { render, staticRenderFns } from "./EditConfigModal.vue?vue&type=template&id=32b0989c&"
import script from "./EditConfigModal.vue?vue&type=script&lang=js&"
export * from "./EditConfigModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports